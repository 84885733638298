<template>
  <div>
    <div class="in-banner">
      <img style="width: 100%" :src="require('@/assets/wechat/5e93c005d074e.jpg')" alt="关于我们">
    </div>
    <!--  联系我们-->
    <div class="ab">
      <div class="w90">
        <div class="title3">
          <h5>联系我们</h5>
          <h6>CONTACT US</h6>
        </div>
        <div class="h30"></div>
        <div class="txt">
          <div style=" margin: auto; overflow: hidden; width: 90%;">
            <el-image style="width: 4rem;    margin-top: 1.6rem;" class="el-col-5"
                      :src="require('@/assets/wechat/a040.png')"></el-image>
            <div class="el-col-offset-2 el-col-16 lc" style="text-align: left">
              <h3>品牌合作</h3>
              <div>
                <label>联系人：</label>
                <h4>宋先生</h4>
              </div>
              <div class="el-row">
                <label>咨询电话：</label>
                <h4>{{ this.company.companyPhone }}</h4>
              </div>
              <div class="el-row">
                <label>邮箱：</label>
                <h4>{{ this.company.companyEmail }}</h4>
              </div>
            </div>
          </div>
          <div class="h30"></div>
          <div style=" margin: auto; overflow: hidden; width: 90%;">
            <el-image style="width: 4rem;    margin-top: 1.6rem;" class="el-col-5"
                      :src="require('@/assets/wechat/a040.png')"></el-image>
            <div class="el-col-offset-2 el-col-16 lc" style="text-align: left">
              <h3>Brand cooperation</h3>
              <div>
                <label>CONTACTS：</label>
                <h4>Mr Song</h4>
              </div>
              <div class="el-row">
                <label>PHONE：</label>
                <h4>{{ this.company.companyPhone }}</h4>
              </div>
              <div class="el-row">
                <label>EMAIL：</label>
                <h4>{{ this.company.companyEmail }}</h4>
              </div>
            </div>
          </div>
          <div class="h30"></div>
          <div style=" margin: auto; overflow: hidden; width: 90%;">
            <el-image style="width: 4rem;    margin-top: 1.6rem;" class="el-col-5"
                      :src="require('@/assets/wechat/a042.png')"></el-image>
            <div class="el-col-offset-2 el-col-16 lc" style="text-align: left">
              <h3>公司地址：</h3>
              <div>
                <h4>{{ this.company.companyAddress }}</h4>
              </div>
            </div>
          </div>
          <div class="h30"></div>
          <div style=" margin: auto; overflow: hidden; width: 90%;">
            <el-image style="width: 4rem;    margin-top: 1.6rem;" class="el-col-5"
                      :src="require('@/assets/wechat/a042.png')"></el-image>
            <div class="el-col-offset-2 el-col-16 lc" style="text-align: left">
              <h3>Company address:</h3>
              <div>
                <h4>{{ this.company.companyAddress }}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="h30"></div>
    </div>
    <div>
      <el-image :src="require('@/assets/wechat/me.png')" @click="detailImg(0)"></el-image>
      <el-image :src="require('@/assets/wechat/bgzh.png')" @click="detailImg(1)"></el-image>
      <el-dialog
          title="详情"
          :visible.sync="visible"
          width="90%"
          heighr="80%"
          :before-close="handleClose">
        <el-image :src="cImage"></el-image>
      </el-dialog>

    </div>
    <template>
      <baidu-map-components class="basic_container_noflex" :map="map"
                            @select-location="selectLocation"></baidu-map-components>
    </template>
    <div class="footer2">
      <div class="w90" style=" width: 90%;
    margin: auto;">
        <span>© 成都市锦荣源纤维科技有限公司 蜀ICP备2021009798号</span>
      </div>
    </div>
  </div>
</template>

<script>
import {httpAction} from "@/api/manage";
import BaiduMapComponents from "@/components/baiduMap";

export default {
  name: "contactus",
  components: {BaiduMapComponents},
  data() {
    return {
      urls: {
        companyInfo: "/companyManage/getCompanyInfo"
      },
      company: {
        companyAddress: "",
        companyPhone: "",
        companyEmail: ""
      },
      map: {
        center: {lng: 104.122761, lat: 30.945554},
        zoom: 15,
        show: true,
        dragging: false,
        isAdd: false,
        keyword: "",
        isSearch: false,
        height: '400px',
      },
      visible: false,
      cImage: {},
      images: [require('@/assets/wechat/me.png'), require('@/assets/wechat/bgzh.png')]
    }
  },
  mounted() {
    this.getCompanyInfo()
  },
  methods: {
    getCompanyInfo() {
      httpAction(this.urls.companyInfo, {body: {}}).then(resp => {
        if (resp.code == 0) {
          Object.assign(this.company, resp.data)
        }
      })
    }, selectLocation: function (e) {
      // 这里用到了antDesign，不再科普了。实际上就是获取子组件传来的数据
      // this.formForAdd.setFieldsValue({
      //   longitude: e.lng,
      //   latitude: e.lat,
      // })
    },
    handleClose(done) {
      done()
    },
    detailImg(i) {
      this.cImage = this.images[i]
      this.visible = true
    }

  }
}
</script>

<style scoped>
.h80 {
  width: 100%;
  height: 80px;
  overflow: hidden;
}

.h60 {
  width: 100%;
  height: 60px;
  overflow: hidden;
}

.h30 {
  width: 100%;
  height: 30px;
  overflow: hidden;
}

.ab {
  width: 100%;
  height: auto;
}

.ab .w90 {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
}

.title3 {
  width: 100%;
  height: auto;
  text-align: center;
}

.title3 h5 {
  font-size: 36px;
  color: #333;
  text-transform: capitalize;
  margin: 2rem;
}

.title3 h6 {
  font-size: 15px;
  text-transform: uppercase;
  color: #666;
  font-family: arial;
}

.lc h4 {
  display: inline-block;
  margin: 0;
  font-weight: normal;
}

.footer2 {
  text-align: center;
  width: 100%;
  height: auto;
  padding: 25px 0;
  background: #333333;
  color: #fff;
  font-family: "微软雅黑", "fut", Arial, "Microsoft Yahei";
}
</style>
